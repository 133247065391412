import React, { Component } from "react";
import { Toast, Modal, Button } from "react-bootstrap";
import FormAdminCom from "../components/FormAdmin_Com";
import SideBar from "../components/SideBar_Com";
import { AuthContext } from "../context/Auth_Context";
import { fetchPerusahaan, fetchSpecificAdmin } from "../logics/FetchData_Logic";
import { getTime } from "../logics/CreateDateTime_Logic";
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";

class FormAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataPerusahaan: null,
            dataEdit: null,
            showToast: [],
            toast_data: [],
            showModal: false,
            modalData: {},
            mainLoading: true
        }
    }

    close(index, pos) {//destroy
        let show_arr = [...this.state.showToast];
        show_arr[index] = false;
        this.setState({
            showToast: show_arr
        }, this.destroy(pos));
    }

    destroy(position) {//nge rerender ketika masih ada yg di show apakah ga bakal masalah?
        let toast_data = [...this.state.toast_data];
        toast_data.splice(position, 1, null);
        this.setState({
            toast_data: toast_data
        })
    }

    move = () => {
        this.props.history.push("/TableAdmin");
    }

    showModal = (type, name, uniq) => {
        this.setState({
            showModal: true,
            modalData: {
                name: name,
                uniq: uniq,
                type: type
            }
        })
    }

    generateToast = (status, message) => {
        let idx = this.state.showToast.length
        let header = "";
        let header_class = ""
        switch (status) {
            case 0: //success
                header_class = "success"
                header = "Success!!"
                break;
            case 1: //error from input
                header_class = "warning"
                header = "Error!!"
                break;
            case 2: //error from server
                header_class = "danger"
                header = "Error!!"
                break;
            default:
                header_class = ""
                header = "Unknown"
                break;
        }
        let data = {
            class: header_class,
            header: header,
            msg: message,
            idx: idx,
            pos: this.state.toast_data.length
        }
        this.setState({
            toast_data: this.state.toast_data.concat(data),
            showToast: this.state.showToast.concat(true)
        })
    }

    async componentDidMount() {
        const { auth, setToken } = this.context
        let response = await fetchPerusahaan(auth.token);
        let timeout = false;
        let dataPerusahaan = null;
        let dataEdit = null;
        let response_2 = null;
        if (response[0] !== undefined && response[0].status === 200) {
            if (response[0].token) setToken(response[0].token);
            dataPerusahaan = response[0].data;
            if (this.props.location.state) {
                response_2 = await fetchSpecificAdmin(auth.token, this.props.location.state._id);
                if (response_2[0] !== undefined && response_2[0].status === 200) {
                    if (response[0].token) setToken(response[0].token);
                    dataEdit = {...response_2[0].data[0]};
                    let isExist = false;
                    for(let i = 0; i < response[0].data.length; i++){
                        if(response[0].data[i]._id === dataEdit.idPerusahaan._id){
                            isExist = true;
                            break;
                        }
                    }
                    if(!isExist) dataEdit.idPerusahaan._id = ""
                } else if (response_2[0] !== undefined && response_2[0].status === 402) {
                    let path = "/";
                    this.props.history.push({
                        pathname: path,
                        state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                    })
                } else if (response_2[1].status && response_2[1].status === 408) {
                    timeout = true
                } else {
                    this.props.history.push("/500ServerError")
                }
            }
            this.setState({
                dataPerusahaan: dataPerusahaan,
                dataEdit: dataEdit,
                timeout:timeout,
                mainLoading: false
            })
            return;
        } else if (response[0] !== undefined && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else if (response[1].status && response[1].status === 408) {
            timeout = true
        } else {
            this.props.history.push("/500ServerError")
        }
        timeout && this.setState({ timeout: timeout, mainLoading: false })
    }

    render() {
        return (
            <div className="home">
                <SideBar />
                <div className="toast_container">
                    {
                        this.state.toast_data.map((item) => {
                            return item !== null && (
                                <Toast animation className={item.class || "unknown"} show={this.state.showToast[item.idx]} onClose={() => this.close(item.idx, item.pos)} autohide delay={5000}>
                                    <Toast.Header>
                                        <strong className={"mr-auto "}>{item.header || "Unknown"}</strong>
                                        <small>{getTime()}</small>
                                    </Toast.Header>
                                    <Toast.Body>{item.msg || "Some undocummented error occured. Please contact the administrator to look at this problem"}</Toast.Body>
                                </Toast>)
                        })
                    }
                    <Modal show={this.state.showModal} onHide={this.move} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Sukses!!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Data admin <b>{this.state.modalData.name}</b> telah berhasil {this.state.modalData.type === "input" ? "didaftarkan" : "diupdate"}.</p>
                            {this.state.modalData.type === "input" &&
                                <>
                                    <p>Password yang dibuat oleh server: <b>{this.state.modalData.uniq}</b></p>
                                    <p><b>Jangan memberitahukan password kepada siapapun kecuali orang yang bersangkutan</b></p>
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.move}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div className="main">
                    {this.state.mainLoading && <ModalLoading />}
                    {this.state.timeout && <Timeout {...this.props}/>}
                    {!this.state.timeout && this.state.dataPerusahaan && <FormAdminCom data={[this.state.dataPerusahaan, this.state.dataEdit]} generateToast={this.generateToast} showModal={this.showModal} {...this.props} />}
                </div>
            </div>
        );
    }
}

FormAdmin.contextType = AuthContext
export default FormAdmin;