import React, { Component } from "react";
import {Modal, Button, Spinner} from "react-bootstrap"
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com"; 
import '../styles/Table_Style.css'
import {AuthContext} from "../context/Auth_Context"
import {fetchProduk} from "../logics/FetchData_Logic"
import {deleteProduk} from "../logics/DeleteData_Logic"
import Timeout from "./Timeout_Page";
import ModalLoading from "../components/ModalLoading_Com";

class TableProduk extends Component {
    constructor(props){
        super(props);

        this.state={
            data:null,
            showModal: false,
            delData:null,
            loading:false,
            successDel:null,
            timeout:false,
            mainLoading: true
        }
    }

    async componentDidMount(){
        const {auth, setToken } = this.context;
        let token = auth.token;
        let obj = await fetchProduk(token);
        let data_table = null;
        let timeout = false;
        if(obj[0]!==undefined){ 
            if(obj[0].status === 402){
                this.props.history.push("/")
            } else if(obj[0].status === 200){
                data_table = {
                    data: obj[0].data,
                    searching:false,
                    scrollY: 341,
                    scrollCollapse:true,
                    columns: [
                        { data: "jenis" },
                        {
                            data: "empty",
                            orderable:false,
                            className: "Center",
                            defaultContent: '<div class="icon_container"><img class="icon delete" src="assets/trash.svg"/></div>'
                        }
                    ],
                    columnDefs: [
                        {
                            targets: 0,
                            type: "text"
                        }
                    ]
                }

                if(obj[0].token) setToken(obj[0].token);
            } else {
                
            }
        } else if(obj[1].status && obj[1].status === 408) {
            timeout = true
        } else {
            this.props.history.push("/500ServerError")
        }
        this.setState({
            data: data_table,
            timeout: timeout,
            mainLoading: false
        })
    }

    confirmation = (data) => {
        this.setState({
            delData:data,
            showModal:true
        })
    }

    handleClose = () => {
        if(this.state.successDel){
            window.location.reload();
        }
        else {
            this.setState({
                showModal:false
            })
        }
    }

    delete = async() => {
        this.setState({
            loading:true
        })
        const {auth, setToken } = this.context;
        let token = auth.token;
        let success = false;
        let response = await deleteProduk(token,this.state.delData._id,this.state.delData.jenis)
        if(response[0] !== undefined){
            if(response[0].status === 200){
                success = true
                if (response[0].token) setToken(response[0].token);
            } else if(response[0].status === 402){
                let path="/";
                this.props.history.push({
                    pathname: path,
                    state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
                })
            } 
        } 
        this.setState({
            loading: false,
            successDel: success
        })
    }

    render() {
        return (
            <div className="home">
                <Modal backdrop="static" show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton={!this.state.loading}>
                        <Modal.Title>Konfirmasi Hapus</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.successDel === null && this.state.delData ? (
                                <>
                                <p>Apakah anda yakin untuk menghapus data berikut:</p>
                                <ul>
                                    <li>Jenis Produk: {this.state.delData.jenis}</li>
                                </ul>
                                </>
                            ) :
                            (
                                this.state.successDel ? 
                                <p>Rekord berhasil dihapus</p>
                                :
                                <p>Error, terjadi kesalahan saat ingin menghapus rekord</p>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.successDel !== null ?
                                <Button variant="secondary" onClick={this.handleClose}>
                                    Close
                                </Button>
                            : (this.state.loading ?
                            <Button variant="danger" disabled>
                                <Spinner animation="border" variant="secondary" />
                            </Button>
                            :
                            <>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button variant="danger" onClick={this.delete}>
                                Delete
                            </Button>
                            </>)
                        }
                    </Modal.Footer>
                </Modal>
                <SideBar/>
                <div className="main">
                    {this.state.mainLoading && <ModalLoading/>}
                    {this.state.timeout && <Timeout {...this.props}/>}
                    <div className="container-table">
                        {this.state.data && <Table {...this.props} delete={this.confirmation} status={"TableProduk"} data={this.state.data}/>}
                    </div>
                </div>
            </div>
        );
    }
}

TableProduk.contextType = AuthContext
export default TableProduk;