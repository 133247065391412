import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Button, Image, Modal, ProgressBar, Row } from "react-bootstrap";
import "../styles/Sidebar_Style.css";
import "../styles/Colour_Style.css";
import { AuthContext } from "../context/Auth_Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faUser,
  faBoxes,
  faBuilding,
  faPaperPlane,
  faClipboard,
  faSignOutAlt,
  faUnlockAlt,
  faUserTie,
  faCar,
  faHands,
  faPeopleCarry,
  faDatabase,
  faClipboardList,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import { fetchNotaData } from "../logics/FetchData_Logic";

class SideBar extends Component {
  constructor(props, context) {
    super(props, context);

    const { auth, setToken, setAuth } = this.context;
    this.state = {
      level: auth.level || localStorage.level,
      token: auth.token || localStorage.token,
      logo: localStorage.logo !== "undefined" || !localStorage.logo ? localStorage.logo : "assets/logo/logotrans2x.png",
      history: this.props.history,
      fetchStatus: false,
      errorFetch: false,
      progress: 0,
      successFetch: true,
      setToken: setToken,
      setAuthData: setAuth,
    };
  }

  logOut = () => {
    localStorage.clear();
    this.state.setAuthData({ token: "", level: "", idPerusahaan: "" });
    this.state.history.push("/");
  };

  onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname);
    };
  };

  refreshData = async () => {
    this.setState({ fetchStatus: true });
    await fetchNotaData(
      this.state.token,
      this.updateProgress,
      this.setError,
      this.relogin
    );
  };

  updateProgress = (response) => {
    response && this.state.setToken(response);
    this.setState({
      progress: this.state.progress + 1,
    });
  };

  setError = (response) => {
    this.setState({
      errorFetch: true,
      errorFetchMsg: response,
    });
  };

  relogin = () => {
    this.props.history.push({
      pathname: "/",
      state:
        "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi.",
    });
  };

  close = () => {
    if (!this.state.errorFetch) {
      window.location.reload();
    } else {
      this.setState({
        fetchStatus: false,
      });
    }
  };

  render() {
    return (
      <>
        <div
          className="sidenav"
          style={{
            backgroundImage: "url('assets/pattern/pattern1.png')",
            backgroundSize: "35vh",
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "40%",
          }}
        >
          <div className="logo-container">
            <Image
              id="logo-sidebar"
              className="logo-pertamina"
              src={this.state.logo}
            />
          </div>
          {/*Bagian Grand Master Admin*/}
          {this.state.level === "3" && (
            <>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TablePerusahaan", "/FormPerusahaan"])}
                to="/TablePerusahaan"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faBriefcase} />
                Perusahaan
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TableAdmin", "/FormAdmin"])}
                to="/TableAdmin"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUser} />
                Admin
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TableProduk", "/FormProduk"])}
                to="/TableProduk"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faBoxes} />
                Produk
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TablePengiriman", "/FormPengiriman"])}
                to="/TablePengiriman"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faPaperPlane} />
                Pengiriman
              </NavLink>
              <NavLink activeClassName="active-link" exact to="/TableLog">
                <FontAwesomeIcon className="icon-sidebar" icon={faClipboard} />
                Log
              </NavLink>
            </>
          )}
          {/*  */}

          {/*Bagian Master Admin Kota */}
          {this.state.level === "2" && (
            <>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/TableSupir",
                  "/FormSupir",
                  "/TablePremi",
                ])}
                to="/TableSupir"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUserTie} />
                Supir / Nakhoda
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TableKendaraan", "/FormKendaraan"])}
                to="/TableKendaraan"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faCar} />
                Armada
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/TablePerusahaanPengiriman",
                  "/FormPerusahaanPengiriman",
                ])}
                to="/TablePerusahaanPengiriman"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faBuilding} />
                Transportir
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TablePenerima", "/FormPenerima"])}
                to="/TablePenerima"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faHands} />
                Penerima
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/TablePengambilanProduk",
                  "/FormPengambilanProduk",
                ])}
                to="/TablePengambilanProduk"
              >
                <FontAwesomeIcon
                  className="icon-sidebar"
                  icon={faPeopleCarry}
                />
                Pengambilan Produk
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/TablePengirimanKota",
                  "/FormPengirimanKota",
                ])}
                to="/TablePengirimanKota"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faPaperPlane} />
                Pengiriman
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TableAdminKota", "/FormAdminKota"])}
                to="/TableAdminKota"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUser} />
                Admin
              </NavLink>
              <NavLink activeClassName="active-link" exact to="/TableLog">
                <FontAwesomeIcon className="icon-sidebar" icon={faClipboard} />
                Log
              </NavLink>
              {/* <NavLink activeClassName="active-link" exact to="/TablePremi"><FontAwesomeIcon className="icon-sidebar" icon={faDatabase}/>Data Premi</NavLink> */}
            </>
          )}

          {/*Bagian Admin Kota Nota */}
          {this.state.level === "1" && (
            <>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/FormNota", "/FormNotaEdit"])}
                to={{ pathname: "/FormNota", state: this.props.notaData }}
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faClipboard} />
                Nota Pengiriman
              </NavLink>
              <NavLink
                activeClassName="active-link"
                isActive={this.onPaths([
                  "/TablePengirimanPerusahaan",
                  "/FormPengirimanPerusahaan",
                ])}
                exact
                to="/TablePengirimanPerusahaan"
              >
                <FontAwesomeIcon
                  className="icon-sidebar"
                  icon={faClipboardList}
                />
                Daftar Pengiriman
              </NavLink>
              {/* <NavLink activeClassName="active-link" exact to="/TablePremi"><FontAwesomeIcon className="icon-sidebar" icon={faDatabase}/>DataPremi</NavLink>  */}
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths(["/TableSupir", "/TablePremi"])}
                to="/TableSupir"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faUserTie} />
                Supir / Nakhoda
              </NavLink>
              <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths("/TableRekapan")}
                to="/TableRekapan"
              >
                <FontAwesomeIcon className="icon-sidebar" icon={faBook} />
                Rekap Pengiriman
              </NavLink>
            </>
          )}
          <div className="btn-logout-container">
            <div className="button-bottom-container">
              <NavLink exact activeClassName="active-link" to="/GantiPassword">
                <FontAwesomeIcon className="icon-sidebar" icon={faUnlockAlt} />
                Ganti Password
              </NavLink>
              <NavLink exact to="/" onClick={this.logOut}>
                <FontAwesomeIcon className="icon-sidebar" icon={faSignOutAlt} />
                Keluar
              </NavLink>
            </div>
            <div className="copyright-container">
              PT. Indotrans Sejahtera&copy;{new Date().getFullYear()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

SideBar.contextType = AuthContext;
export default withRouter(SideBar);
