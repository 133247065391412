import React, {Component} from "react"
import {Image, Row, Col} from "react-bootstrap"
import '../styles/Colour_Style.css'
import '../styles/Form_Style.css'

class ServerError extends Component{
    render(){
        return(
            <div>
                <Image style={{position:"absolute", top:"0", left:"0"}} src="assets/pattern/pattern1.png"/>
                <Image style={{position:"absolute", bottom:"0", right:"0"}} src="assets/pattern/pattern2.png"/>
                <div className="unauthorize-container">
                    <Row>
                        <Col id="logo-unauthorized" sm={4}>
                            <Image src="assets/logo/logotrans2x.png"/>
                        </Col>
                        <Col sm={7} style={{display:"flex", justifyContent:"center", alignItems:"center", marginLeft:"20px"}}>
                            <h1 style={{fontSize:"5vh"}}>500<br/>Internal Server Error</h1>
                            <h2 style={{fontSize:"3vh"}}>Silahkan hubungi administrator.</h2>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
export default ServerError;