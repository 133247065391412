import React, { Component } from "react";
import FormPengirimanCom from "../components/FormPengiriman_Com";
import SideBar from "../components/SideBar_Com";
import { AuthContext } from "../context/Auth_Context";
import { fetchSpecificPengiriman } from "../logics/FetchData_Logic";
import { Spinner, Modal, Button } from "react-bootstrap";
import Timeout from "./Timeout_Page";
import InvalidAccess from "./InvalidAccess_Page";
import ModalLoading from "../components/ModalLoading_Com";

class FormPengiriman extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            showModal: false,
            saveFunc: null,
            loading: false,
            success: null,
            timeout: false,
            unknownAccess: false,
            mainLoading: true
        }
    }

    async componentDidMount() {
        const { auth, setToken } = this.context;
        let timeout = false;
        let unknownAccess = false;
        let data = null;
        let response = await fetchSpecificPengiriman(auth.token, this.props.location.state ? this.props.location.state._id : "empty");
        if (response[0] !== undefined && response[0].status === 200) {
            if (response[0].token) setToken(response[0].token);
            data = response[0].data[0];
        } else if (response[0] !== undefined && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else if (response[1].status && response[1].status === 408) {
            timeout = true
        } else if (response[1].status && response[1].status === 400) {
            unknownAccess = true
        } else {
            this.props.history.push("/500ServerError")
        }
        this.setState({
            data: data,
            timeout: timeout,
            unknownAccess: unknownAccess,
            mainLoading: false
        })
    }

    showModal = () => {
        this.setState({
            showModal: !this.state.showModal
        })
    }

    setSave = (saveFunc) => { this.setState({ saveFunc: saveFunc }) }

    save = () => {
        this.state.saveFunc();
    }

    setLoading = () => {
        this.setState({
            loading: !this.state.loading
        })
    }

    setSuccess = (status) => {
        this.setState({
            loading: false,
            success: status
        })
    }

    move = () => {
        this.props.history.push("/TablePengirimanPerusahaan")
    }

    render() {
        return (
            <div className="home">
                <SideBar />
                <Modal show={this.state.showModal} onHide={this.state.success ? this.move : this.showModal} backdrop="static">
                    <Modal.Header closeButton={!this.state.loading}>
                        <Modal.Title>{this.state.success === null ? "Perhatian!!" : this.state.success ? "Suskes!!" : "Error!!"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.success === null ?
                                <>
                                    <p>Tindakan ini akan mengubah keterangan nota, sekaligus juga mengubah status nota dari <b>dalam pengiriman</b> menjadi <b>selesai</b>.</p>
                                    <p>Apakah anda yakin?</p>
                                </>
                                :
                                this.state.success ?
                                    <p>Data keterangan dan status nota berhasil diupdate.</p>
                                    :
                                    <p>Terjadi kesalahan ketika mengupdate keterangan dan status nota.</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.loading ?
                                <Button variant="primary">
                                    <Spinner animation="border" variant="secondary" />
                                </Button>
                                :
                                this.state.success === null ?
                                    <>
                                        <Button variant="secondary" onClick={this.showModal}>
                                            Close
                                    </Button>
                                        <Button variant="primary" onClick={this.save}>
                                            Save
                                    </Button>
                                    </>
                                    :
                                    <Button variant="secondary" onClick={this.state.success ? this.move : this.showModal}>
                                        Close
                                </Button>
                        }
                    </Modal.Footer>
                </Modal>
                <div className="main">
                    {this.state.mainLoading && <ModalLoading/>}
                    {this.state.timeout && <Timeout {...this.props} />}
                    {this.state.unknownAccess && <InvalidAccess />}
                    {this.state.data !== null &&
                        <FormPengirimanCom {...this.props}
                            data={this.state.data}
                            setLoading={this.setLoading}
                            setSuccess={this.setSuccess}
                            setSave={this.setSave}
                            showModal={this.showModal}
                        />}
                </div>
            </div>
        );
    }
}

FormPengiriman.contextType = AuthContext;
export default FormPengiriman;