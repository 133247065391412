import React, { Component } from "react";
import SideBar from "../components/SideBar_Com";
import Table from "../components/Table_Com";
import '../styles/Table_Style.css'
import { fetchPengiriman, fetchPremi } from "../logics/FetchData_Logic";
import { AuthContext } from "../context/Auth_Context";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { editStatusPremi } from "../logics/SubmitForm_Logic";
import { formatNumber } from "../logics/FormatNumberPreview_Logic";
import Timeout from "./Timeout_Page";
import InvalidAccess from "./InvalidAccess_Page";
import * as jsPDF from "jspdf";
import "jspdf-autotable";
import ModalLoading from "../components/ModalLoading_Com";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class TablePremi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            showModal: false,
            modal_data: {},
            loading: false,
            token: null,
            success: null,
            row: null,
            funcPremi: null,
            timeout: false,
            printPremi: false,
            month: false,
            year: false,
            loading: false,
            mainLoading: true,
            emptyData: false,
            startDate: new Date()
        }

        this.nama = ""
        this.no_polisi = props.location.state.polisi
        // this.month = null
        // this.year = null
        this.dataTable = null
        this.convertMonth = {
            1: 'Januari',
            2: 'Februari',
            3: 'Maret',
            4: 'April',
            5: 'Mei',
            6: 'Juni',
            7: 'Juli',
            8: 'Agustus',
            9: 'September',
            10: 'Oktober',
            11: 'November',
            12: 'Desember'
        }
    }

    async componentDidMount() {
        const { auth, setToken } = this.context;
        this.setState({
            token: auth.token
        })
        let timeout = false;
        let unknownAccess = false;
        let obj = await fetchPremi(auth.token, this.props.location.state.id || "");
        let data_table = null;
        if (obj[0] !== undefined && obj[0].status === 200) {
            this.nama = obj[0].data[0].length > 0 ? obj[0].data[0][0].nama_supir : this.props.location.state.nama
            data_table = {
                data: obj[0].data[0],
                scrollY: 313,
                scrollCollapse: true,
                columns: [
                    {
                        data: "tanggal_pengirim",
                        render: function (data, type, row, meta) {
                            let date = new Date(data);
                            if (type === 'display' || type === 'filter') {
                                if (data === "~") return data
                                let year = date.getFullYear();
                                let month = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                                let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                                return day + "-" + month + "-" + year;
                            }
                            if (data === '~') return 0;
                            else return date.getTime() / 1000;
                        }
                    },
                    { data: "no_polisi" },
                    {
                        data: "tujuan_pengiriman",
                        render: function (data) {
                            if (data === "~") return data
                            return data ? data.nama_perusahaan : "-"
                        }
                    },
                    {
                        data: "total_harga",
                        defaultContent: 0
                    },
                    {
                        data: "biaya_premi",
                        defaultContent: 0,
                        render: function (data) {
                            if (data === "~") return data
                            return formatNumber(data)
                        }
                    },
                    auth.level === "1" ? {
                        data: "status_Premi", //"status_premi"
                        orderable: false,
                        render: function (data) {
                            if (data === "~") return data
                            else if (data) return '<button class="status_premi_done" disabled>Sudah Diambil</Button>'
                            else return '<button class="status_premi">Ambil</Button>'
                        }
                    } : {
                            data: "status_Premi",
                            orderable: false,
                            render: function (data) {
                                if (data === "~") return data
                                else if (data) return "Sudah Ambil"
                                return "Belum Ambil"
                            }
                        }
                ],
                columnDefs: [
                    {
                        render: function (data, type, row) {
                            if (data === "~") return data
                            return formatNumber(row.harga_produk * row.kuantitas);
                        },
                        targets: 3,
                        type: "number"
                    },
                    {
                        targets: 0,
                        type: "dates"
                    },
                    {
                        targets: [1,2],
                        type: "text"
                    },
                    {
                        targets: 4,
                        type: "number"
                    }
                ],
                order: [[0, 'desc']]
            }
            if (obj[0].token) setToken(obj[0].token);
        } else if (obj[0] !== undefined && obj[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        } else if (obj[1].status && obj[1].status === 408) {
            timeout = true
        } else if (obj[1].status && obj[1].status === 400) {
            unknownAccess = true
        } else {
            this.props.history.push("/500ServerError")
        }
        this.setState({
            data: data_table,
            timeout: timeout,
            unknownAccess: unknownAccess,
            mainLoading: false
        })
    }

    setDataTable = (dt) => {
        this.dataTable = dt;
    }

    handleChange = (event) => {
        if (event.target.name === "bulan") this.month = event.target.value
        else this.year = event.target.value
    }

    doPrintPremi = () => {
        if (this.state.startDate) {
            this.setState({
                loading: true
            })
            let dateBelow = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth() , 1)
            let dateUp = new Date(this.state.startDate.getFullYear(), this.state.startDate.getMonth()+1, 0)
            dateUp.setDate(dateUp.getDate()+1);
            let li = []
            this.dataTable.rows().every(function (rowIdx, tableLoop, rowLoop) {
                var data = this.data();
                var d = new Date(data.tanggal_pengirim); //kedepannya kalau ada masalah dalam filter row premi untuk di print bisa di cek disini
                if (d >= dateBelow && d <= dateUp && !data.status_Premi) li.push(data)
            })
            let emptyData = false
            if (li.length > 0) {
                let data = []
                let idx = 1
                li.forEach(element => {
                    let date = new Date(element.tanggal_pengirim)
                    data.push([
                        idx,
                        date.getDate() + "-" + (this.convertMonth[date.getMonth() + 1]).toUpperCase().substring(0, 3),
                        element.no_polisi,
                        element.tujuan_pengiriman.nama_perusahaan,
                        formatNumber(element.kuantitas),
                        "Rp " + formatNumber(element.harga_produk),
                        "Rp " + formatNumber(element.kuantitas * element.harga_produk),
                        element.liter ? formatNumber(element.liter) : "-",
                        "Rp " + formatNumber(element.biaya_premi)
                    ])
                    idx = idx + 1
                })
                this.constructPdf(data);
            } else {
                emptyData = true
            }
            this.setState({
                loading: false,
                emptyData: emptyData
            }, async () => {
                await this.sleep(10000)
                this.setState({
                    emptyData: false
                })
            })
        }
    }

    constructPdf = (data) => {
        (function (API) { //thx stackoverflow
            API.myText = function (txt, options, x, y) {
                options = options || {};
                if (options.align == "center") {
                    var fontSize = this.internal.getFontSize();
                    var pageWidth = this.internal.pageSize.width;
                    var txtWidth = this.getStringUnitWidth(txt) * fontSize / this.internal.scaleFactor;
                    x = (pageWidth - txtWidth) / 2;
                }
                this.text(txt, x, y);
            }
        })(jsPDF.API);

        var nota = new jsPDF('landscape', 'pt', 'a4');
        nota.setFontSize(18);
        nota.myText("REKAP UANG PREMI BULAN " +
            (this.convertMonth[this.state.startDate.getMonth()+1]).toUpperCase() + " " + this.state.startDate.getFullYear(),
            { align: "center" }, 0, 40)
        nota.setFontSize(14);
        nota.myText(this.nama+" ("+this.no_polisi+")", { align: "center" }, 0, 60)
        nota.setFontSize(12);
        nota.autoTable({
            head: [["NO", "TANGGAL", "NOMOR MOBIL", "CUSTOMER", "MUATAN (LITER)", "HARGA PER-LITER", "TOTAL HARGA", "PEMAKAIAN (LITER)", "PREMI"]],
            body: data,
            startY: 90,
            theme: "grid",
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 1,
                fontSize: 10,
                minCellHeight: 20
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                halign: "center",
                valign: "middle",
                minCellHeight: 0
            },
            bodyStyles: {
                halign: "center",
                cellWidth: "wrap"
            },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 60 },
                2: { cellWidth: 70 },
                3: { cellWidth: 210 },
                4: { cellWidth: 70 },
                5: { cellWidth: 80 },
                6: { cellWidth: 100 },
                7: { cellWidth: 70 },
                8: { cellWidth: 70 },
            },
        });

        //page numbering for js pdf, thanks https://github.com/MrRio/jsPDF/issues/109
        const pages = nota.internal.getNumberOfPages();
        const pageWidth = nota.internal.pageSize.width;  //Optional
        const pageHeight = nota.internal.pageSize.height;  //Optional
        nota.setFontSize(10);  //Optional
                
        for (let j = 1; j < pages + 1 ; j++) {
            let horizontalPos = pageWidth / 2;  //Can be fixed number
            let verticalPos = pageHeight - 15;  //Can be fixed number
            nota.setPage(j);
            //nota.text(`${j} of ${pages}`, horizontalPos, verticalPos, {align: 'center'  //Optional text styling});
            nota.myText(`Halaman ${j} / ${pages}`, { align: "center" }, horizontalPos, verticalPos);
        }
        nota.autoPrint();
        window.open(nota.output("bloburl"))
    }

    printPremi = () => {
        this.setState({
            printPremi: !this.state.printPremi
        })
    }

    updatePremi = (data, row) => {
        this.setState({
            showModal: true,
            modal_data: data,
            row: row
        })
    }

    linkFunc = (func) => {
        this.setState({
            funcPremi: func
        })
    }

    doUpdatePremi = async () => {
        this.setState({
            loading: true
        })
        let response = await editStatusPremi(this.state.token, this.state.modal_data._id, this.state.modal_data.no_nota);
        if (response[0] !== undefined && response[0].status === 200) {
            this.setState({
                loading: false,
                success: true
            }, this.state.funcPremi(this.state.row))
            return;
        } else if (response[0] !== undefined && response[0].status === 402) {
            let path = "/";
            this.props.history.push({
                pathname: path,
                state: "Token anda telah expire akibat terlalu lama diam. Silahkan log-in lagi."
            })
        }
        this.setState({
            loading: false,
            success: false
        })
    }

    handleClose = () => {
        this.setState({
            showModal: false,
            success: null
        })
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    render() {
        return (
            <div className="home">
                <SideBar />
                <Modal show={this.state.showModal} onHide={this.handleClose} backdrop={this.state.loading?'static':true}>
                    <Modal.Header closeButton={!this.state.loading}>
                        <Modal.Title>Perhatian!! </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.success === null ?
                            <><div style={{textAlign:"left"}}>
                                <p>Anda akan mengubah status premi untuk no. nota: <b>{this.state.modal_data.no_nota || ""}</b></p>
                                <p>Nama supir yang bersangkutan: <b>{this.state.modal_data.nama_supir || ""}</b></p>
                                <p>Perubahan status: <b>Belum Ambil menjadi Sudah Ambil</b></p>
                            </div>
                            </>
                            :
                            this.state.success ?
                                <p>Status premi berhasil diupdate!</p> :
                                <p>Error: Terjadi kesalahan ketika sedang mengupdate status premi</p>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.success === null ?
                            <>
                                {!this.state.loading &&
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Cancel
                        </Button>}
                                <Button variant="primary" onClick={this.doUpdatePremi} disabled={this.state.loading}>
                                    {this.state.loading ? <Spinner animation="border" variant="secondary" /> : "Save"}
                                </Button>
                            </>
                            :
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                        </Button>
                        }
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.printPremi} onHide={this.printPremi} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Print Premi </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Form>
                            <Form.Group controlId="formMonth">
                                <Form.Label>Bulan</Form.Label>
                                <Form.Control
                                    name="bulan"
                                    as="select"
                                    type="text"
                                    onChange={this.handleChange}
                                    isInvalid={this.state.month}
                                >
                                    <option value="">Pilih Bulan</option>
                                    {this.createMonth()}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Silahkan pilih bulan.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formMonth">
                                <Form.Label>Tahun</Form.Label>
                                <Form.Control
                                    name="tahun"
                                    as="select"
                                    type="text"
                                    onChange={this.handleChange}
                                    isInvalid={this.state.year}
                                >
                                    <option value="">Pilih Tahun</option>
                                    {this.createYear()}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Silahkan pilih tahun.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form> */}
                        <Form.Group>
                            <p>
                                Pilih Bulan {"&"} Tahun yang akan di print
                            </p>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={date => this.setState({ startDate: date, emptyData: false })}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    pattern="[0-9]"
                                />
                        </Form.Group>
                        {
                            this.state.emptyData &&
                            <div style={{color:"red", textAlign:"center"}}>
                                Data yang anda cari untuk bulan {this.convertMonth[this.state.startDate.getMonth()+1]} tahun {this.state.startDate.getFullYear()} <br/> tidak ada / kosong.
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.printPremi}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.doPrintPremi} disabled={this.state.loading}>
                            {this.state.loading ? <Spinner animation="border" variant="secondary" /> : "Print"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="main">
                    {this.state.mainLoading && <ModalLoading />}
                    {this.state.timeout && <Timeout {...this.props} />}
                    {this.state.unknownAccess && <InvalidAccess />}
                    <div className="container-table">
                        {this.state.data &&
                            <>
                                <div id="nama-supir">
                                    <h4>Nama Supir : {this.nama}</h4>
                                </div>
                                <Table dataTable={this.setDataTable} printPremi={this.printPremi} updatePremi={this.updatePremi} link={this.linkFunc} {...this.props} status={"TablePremi"} data={this.state.data} />
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

TablePremi.contextType = AuthContext
export default TablePremi;